
import { defineComponent, watch, ref } from "vue";
import { Field } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "renewal-options-modal-component",
  components: { Field },
  props: {
    details: Object,
  },
  setup(props) {
    const { useReusableNavigation } = ResusableFunctions();
    const routeDetails = ref({
      title: "Renewal Options",
      oneClickRoute: "",
      renewalRoute: "",
    });

    const redirect = (route: string) => {
      hideModal(document.getElementById("renewal_option_modal"));

      setTimeout(() => {
        useReusableNavigation(true, route);
      }, 100);
    };

    watch(
      () => props.details,
      (newDetails: any) => {
        const code = newDetails?.code;

        if (code != undefined) {
          const isMotor = code?.trim().toUpperCase() === "MOT";

          routeDetails.value = {
            title: isMotor
              ? "Motor Renewal Options"
              : "Housing Renewal Options",
            oneClickRoute: isMotor
              ? `/policy/renewal/motor-insurance/preview/${newDetails?.publicId}`
              : `/policy/renewal/house-insurance/preview/${newDetails?.publicId}`,
            renewalRoute: newDetails?.route,
          };
        }
      },
      { immediate: true }
    );

    return { redirect, routeDetails };
  },
});
